import { Navigate } from 'react-router-dom'
import { AuthStoreInstance } from '../helpers/Auth' 
// Component
import PageNotFound from '../pages/404/index'
import Widgets from '../pages/widgets/index'
import Produits from '../pages/Produits'
import Champs from '../pages/champs/index'
import Etats from '../pages/Etats/index'
import Users from '../pages/Users/index'
import Role from '../pages/Role/index'
import Inscription from '../pages/Inscription/index'
import Login from '../pages/Login/index'


const PrivateRoute = ({children}: any)  => {
  return AuthStoreInstance.isAuthenticated ? children : <Navigate to='/login' />;
};
const setLoader = async (loader: any, req?: any) => {
  if(AuthStoreInstance.isAuthenticated) {
    return loader(req)
  }
  return Promise
}
// Loaders 
import { getAllProductsList, getProductData, getAllWidgetsChamps, getEtatDate, getAllUsers, getAllRoles, getUserInviteInfo } from '../querys/userQuery'

export const ROOTS: any = {
  PRIVATE: [
    {element:<PrivateRoute><Produits/></PrivateRoute>, path: '/', caseSensitive: true, loader: async (req: any) => {
      return setLoader(getAllProductsList)
    }, errorElement:<PageNotFound />},
    {element:<PrivateRoute><Widgets/></PrivateRoute>, path: '/widgets/:id', loader: async (req: any) => {
      return setLoader(getProductData, req)
    }, errorElement:<PageNotFound />},
    {element:<PrivateRoute><Champs/></PrivateRoute>, path: '/champs/:id', loader: async (req: any) => {
      return setLoader(getAllWidgetsChamps, req)
    }, errorElement:<PageNotFound />},
    {element:<PrivateRoute><Etats/></PrivateRoute>, path: '/etats/:id', loader: async (req: any) => {
      return setLoader(getEtatDate, req)
    }, errorElement:<PageNotFound />},
    {element:<PrivateRoute><Users/></PrivateRoute>, path: '/users', loader: async (req: any) => {
      return setLoader(getAllUsers)
    }, errorElement:<PageNotFound />},
    {element:<PrivateRoute><Role/></PrivateRoute>, path: '/roles', loader: async (req: any) => {
      return setLoader(getAllRoles)
    }, errorElement:<PageNotFound />}
  ],
  PUBLIC: [
    {element:<Inscription/>, path: '/inscription/:token', loader: getUserInviteInfo, errorElement:<PageNotFound />},
    {element:<Login/>, path: '/login', errorElement:<PageNotFound />},
    {element:<PageNotFound/>, path: '*'},
  ]
}