import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Table, Avatar, Tooltip, Empty } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

interface Props {
  widgets: any[]
}

const Widgets: React.FC<Props> = (props: any) => {
  const navigate = useNavigate()

  const widgets = props.widgets.sort((w: any, y: any) => {
    return (w.default === y.default) ? 0 : w.default ? -1 : 1;
  })

  interface Widgets {
    id: number;
    defaut: boolean;
    titre: string;
    description: string;
    statut: string;
    couleur: string;
  }

  const dataTable = widgets?.map((widget: any) => ({
    id: widget.id,
    defaut: widget.default,
    titre: widget.nom,
    description: widget.description,
    statut: widget.active,
    couleur: widget.widget_color
  }))

  const columns = [
    {
      title: '-',
      dataIndex: 'defaut',
      width: 40,
      className: 'text-center',
      render: (text: any, record: Widgets)=> (
        <>{ record.defaut ? <Tooltip title='Widget par défaut' arrow={false}><FontAwesomeIcon icon={faCheck} color='green' /></Tooltip> : '' }</>
      )
    },
    {
      title: 'Titre',
      dataIndex: 'titre',
      className: 'fw-bold',
      sorter: (a: Widgets, b: Widgets) => a.titre.localeCompare(b.titre),
      showSorterTooltip: false
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text: any, record: Widgets)=> (
        <div className='ellipsis-7'>{ record.description }</div>
      )
    },
    {
      title: 'Couleur',
      dataIndex: 'couleur',
      width: 90,
      className: 'text-center',
      render: (text: any, record: Widgets)=> (
        <>{ record.couleur ? <Tooltip title={record.couleur} arrow={false} overlayClassName='small'><Avatar style={{ backgroundColor: record.couleur }} /></Tooltip> : '' }</>
      )
    }
  ]

  return (
    dataTable.length === 0 ?
      <div className='hpx-200 d-flex align-items-center justify-content-center text-center'><Empty /></div>
    :
      <Table
        columns={columns}
        dataSource={dataTable}
        pagination={ dataTable.length > 15 ? { pageSize: 15 } : false }
        rowKey='id'
        rowClassName={() => 'cursor'}
        onRow={(record: Widgets) => ({onClick: () => navigate('/champs/' + record.id)})}
      />
  )
}

export default Widgets
