const colorPalettes = [
  {
    name: 'default',
    fill: '#',
    class: ''
  },
  {
    name: 'dark',
    fill: '#000000',
    class: ''
  },
]

export default colorPalettes
