import { useState } from 'react'
import { useNavigate, useLoaderData } from 'react-router-dom'

import { Row, Col, Card, Button, Dropdown, Badge, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faAdd, faEdit } from '@fortawesome/free-solid-svg-icons'

import ModalWidget from '../../components/WidgetComponents/ModalWidget'
import Widget from '../../components/WidgetComponents/Widget'
import ModalProduit from '../../components/ProduitComponents/ModalProduit'
import EtatsModal from '../../components/EtatsComponent/EtatsModal'
import { ENDPOINTS } from '../../constants/api'
import ApiRequest from '../../helpers/ApiRequest'

function Widgets() {
  const navigate = useNavigate()
  const { product, widgets, etatsOptions }: any = useLoaderData()
  const productId = product.id
  const hasDefault = widgets?.filter((w: any) => w.isDefault).legend
  const [isProductModalOpen, setIsProductModalOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEtatModalOpen, setIsEtatModalOpen] = useState(false)

  const handleClick = () => {
    setIsModalOpen(true)
  }

  const handleClickEtats = () => {
    setIsEtatModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setIsEtatModalOpen(false)
  }

  const handleEtatModalClose = () => {
    setIsEtatModalOpen(false)
  }

  const handleBack = () => {
    navigate(-1)
  }

  const handleValidClick = async (newWidget: any) => {
    const response: any = await ApiRequest.request({data: {...newWidget, produit_id: productId}, method: 'POST', endpoint: ENDPOINTS.addWidgets})
    navigate(`/champs/${response.data.id}`)
  }

  const handleProductModalValid = async (newProduct: any) => {
    await ApiRequest.request({data: newProduct, method: 'POST', endpoint: ENDPOINTS.updateProduct})
    setIsProductModalOpen(false)
  }

  const handleProductModalClose = () => {
    setIsProductModalOpen(false)
  }

  const openProductModal = () => {
    setIsProductModalOpen(true)
  }

  const handleEtatValidClick = async (newEtat: any) => {
    const { nom, description, selectedProduct } = newEtat
    const response: any = await ApiRequest.request({data: {
      produit_id : productId,
      nom,
      description,
      productOptions: selectedProduct
    }, method: 'POST', endpoint: ENDPOINTS.addEtats})

    navigate(`/etats/${response.data.id}`)
  }

  return (
    <>
      <main>
        <div className='container'>
          <Space className='w-100 justify-content-between'>
            <Button size='large' onClick={handleBack}>
              <FontAwesomeIcon icon={faChevronLeft} className='me-2' /> Retour
            </Button>

            <Button size='large' type='primary' onClick={openProductModal}>
              <FontAwesomeIcon icon={faEdit} className='me-2' /> Modifier
            </Button>
          </Space>

          <Badge.Ribbon text={product.active ? 'Activé' : 'Désactivé'} color={product.active ? 'green' : 'red'}>
            <Card className='mt-5 text-center border-linear'>
              <Row justify='center' align='middle'>
                <Col lg={12}>
                  <h1 className='my-0'>{product.label}</h1>
                  { product.note && <div className='mt-5'>{product.note}</div> }
                </Col>
              </Row>
            </Card>
          </Badge.Ribbon>

          <Space size='large' className='w-100 justify-content-end mt-10'>
            {
              etatsOptions.length > 0 &&
              (
                <Dropdown
                  menu={{ items: etatsOptions }}
                  placement="bottomRight"
                >
                  <Button size='large'>
                    Liste Etats
                  </Button>
                </Dropdown>
              )
            }

            <Button size='large' onClick={handleClickEtats}>
              <FontAwesomeIcon icon={faAdd} className='me-2' /> Etats
            </Button>

            <Button size='large' onClick={handleClick}>
              <FontAwesomeIcon icon={faAdd} className='me-2' /> Widget
            </Button>
          </Space>

          <Card className='mt-3 p-2'>
            <Widget widgets={widgets} />
          </Card>
        </div>
      </main>

      {
        isModalOpen &&
        (
          <ModalWidget
            title='Ajouter un widget'
            isModalOpen={isModalOpen}
            handleModalClose={handleModalClose}
            handleValidClick={handleValidClick}
            hasDefault={hasDefault}
          />
        )
      }

      {
        isEtatModalOpen &&
        (
          <EtatsModal
            title='Ajouter un Etats'
            isModalOpen={isEtatModalOpen}
            handleModalClose={handleEtatModalClose}
            handleValidClick={handleEtatValidClick}
            hasDefault={hasDefault}
          />
        )
      }

      {
        isProductModalOpen &&
        (
          <ModalProduit
            title='Modifier le produit'
            isModalOpen={isProductModalOpen}
            handleModalClose={handleProductModalClose}
            handleValidClick={handleProductModalValid}
            hasDefault={hasDefault}
            product={product}
          />
        )
      }
    </>
  )
}

export default Widgets
