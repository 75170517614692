import { useState } from 'react'
import { useNavigate, useLoaderData } from 'react-router-dom'
 
import {
  Card,
  Row,
  Col,
  Space,
  Form,
  Input,
  Button,
  message,
  Result,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import ApiRequest from '../../helpers/ApiRequest'
import { ENDPOINTS } from '../../constants/api'

function Inscription() {
  const [form] = Form.useForm()
  const [passwordError, setPasswordError] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const navigate = useNavigate()
  const {emailFromToken, idUser}: any = useLoaderData()

  const onFinish = async (values: any) => {
    try {
      const formData = {
        prenom: values.prenom,
        nom: values.nom,
        email: emailFromToken,
        password: values.password,
      }

      await ApiRequest.request({data: formData, method: 'PUT', endpoint: ENDPOINTS.updateUser.replace(':id', idUser)})
      // Rediriger l'utilisateur vers la page de connexion
      navigate('/')

      // Affichez un message de succès après l'inscription réussie
      message.success('Inscription effectuée avec succès, mais il faut attendre la validation de votre compte')
    } catch (error) {
      console.error("Erreur lors de la vérification de l'email :", error)
      // Affichez un message d'erreur en cas d'échec de l'inscription
      message.error("Erreur lors de l'inscription.")
    }
  }

  return (
    <main>
      <section className="min-hv-100 d-flex flex-column align-items-center justify-content-center">
        <div className="w-100 mwpx-500">
          {emailFromToken ? (
            <Card>
              <Form
                form={form}
                name="registration"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Space size="middle" direction="vertical" className="w-100">
                  <h1 className="my-0 fs-3 text-center">Inscription</h1>

                  <Row gutter={16}>
                    <Col span={24} lg={12}>
                      <label>
                        Nom <span className="text-danger">*</span>
                      </label>
                      <Form.Item
                        name="nom"
                        rules={[
                          {
                            required: true,
                            message: 'Veuillez entrer votre nom',
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>

                    <Col span={24} lg={12}>
                      <label>
                        Prénom <span className="text-danger">*</span>
                      </label>
                      <Form.Item
                        name="prenom"
                        rules={[
                          {
                            required: true,
                            message: 'Veuillez entrer votre prénom',
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div>
                    <label>
                      Adresse mail{' '}
                      <span className="small text-danger">
                        (non modifiable)
                      </span>
                    </label>
                    <div className="p-2 opacity-50 rounded-1 bg-black-1">
                      {emailFromToken}
                    </div>

                    <Form.Item
                      className="d-none"
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          message: "L'adresse email n'est pas valide",
                        },
                      ]}
                    >
                      <Input defaultValue={emailFromToken} disabled />
                    </Form.Item>
                  </div>

                  {/* todo // revoir le mot de passe si valide */}
                  <Row gutter={16}>
                    <Col span={24} lg={12}>
                      <div>
                        <label>
                          Mot de passe <span className="text-danger">*</span>
                        </label>
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Veuillez entrer un mot de passe',
                            },
                            {
                              pattern:
                                /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
                              message:
                                'Le mot de passe n est pas valide',
                            },
                          ]}
                          hasFeedback
                        >
                          <Input.Password
                            size="large"
                            onChange={(e) => {
                              const newPassword = e.target.value
                              if (
                                !newPassword.match(
                                  /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/
                                )
                              ) {
                                setIsPasswordValid(false)
                              } else {
                                setPasswordError('Le mot de passe est valide')
                                setIsPasswordValid(true)
                              }
                            }}
                          />
                        </Form.Item>
                        <p
                          style={{
                            color: isPasswordValid ? '#52c41a' : '#ff0000',
                          }}
                        >
                          {passwordError}
                        </p>
                      </div>
                    </Col>

                    <Col span={24} lg={12}>
                      <div>
                        <label>
                          Confirmer le mot de passe{' '}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Item
                          name="confirm"
                          dependencies={['password']}
                          hasFeedback
                          rules={[ 
                            {
                              required: true,
                              message: 'Veuillez confirmer votre mot de passe',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue('password') === value
                                ) {
                                  return Promise.resolve()
                                }
                                return Promise.reject(
                                  new Error(
                                    'Les deux mots de passe ne correspondent pas'
                                  )
                                )
                              },
                            }),
                          ]}
                        >
                          <Input.Password size="large" />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <div className="d-flex align-items-center p-2 small rounded-1 bg-default-2">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      size="2x"
                      className="me-3"
                    />{' '}
                    Le mot de passe doit contenir au moins 8 caractères, dont au
                    moins une majuscule, un chiffre et un caractère spécial.
                  </div>

                  <Form.Item className="mt-5 text-center">
                    <Button size="large" htmlType="submit">
                      S'inscrire
                    </Button>
                  </Form.Item>
                </Space>
              </Form>
            </Card>
          ) : (
            <Result
              status="403"
              title="Erreur"
              subTitle="Veuillez contacter l’administrateur"
            />
          )}
        </div>
      </section>
    </main>
  )
}

export default Inscription
