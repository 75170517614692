import { useState } from 'react'
import { Space, Button, Col, Input, Row, Select, message } from 'antd'
import { getAllRolesData } from '../../querys/userQuery'
import ApiRequest from '../../helpers/ApiRequest'
import { ENDPOINTS } from '../../constants/api'


// Interface pour les propriétés
interface Props {
  user: any
  onSave: (user: any) => void
  handleModalClose: () => void
  disablePassword?: boolean
}


function EditProfil({ user, onSave, handleModalClose, disablePassword }: Props) {
  let roles: any = []
  const [currentUser, setCurrentUser] = useState<any>(user);
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  
  const {isLoading, data}: any = getAllRolesData()
  roles = data

  // Fonction pour valider le mot de passe
  const validatePassword = (password: string) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    return passwordRegex.test(password)
  }

  const updateUser = (e: any) => {
    setCurrentUser((prev: any) => {
      prev[e.target.id] = e.target.value
      return prev
    })
  }

  const handleSave = async () => {
    try {
      if (!isPasswordValid && !disablePassword) {
        message.error(
          'Le mot de passe doit contenir au moins 8 caractères, dont au moins une majuscule, un chiffre et un caractère spécial.'
        )
        return
      }
      const requestBody = { ...currentUser};

      await ApiRequest.request({data: requestBody, method: 'PUT', endpoint: ENDPOINTS.updateUser.replace(':id', currentUser.id)})
      console.log(requestBody)
      message.success('Profil mis à jour avec succès')
      console.log(currentUser)
      onSave(requestBody)

    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error)
      message.error('Erreur lors de la mise à jour du profil')
    }
  }

  return (
    <div>
      <Row gutter={[16, 24]}>
        <Col span={24} lg={12}>
          <label className="d-block mb-1">
            Nom <span className="text-danger">*</span>
          </label>
          <Input
            id="nom"
            type="text"
            size="large"
            autoFocus
            defaultValue={currentUser?.nom}
            placeholder="Nom"
            required
            onChange={(e) => {
              updateUser(e)
            }}
          />
        </Col>
        <Col span={24} lg={12}>
          <label className="d-block mb-1">
            Prénom <span className="text-danger">*</span>
          </label>
          <Input
            id="prenom"
            type="text"
            size="large"
            autoFocus
            defaultValue={currentUser?.prenom}
            placeholder="Prenom"
            required
            onChange={(e) => {
              updateUser(e)
            }}
          />
        </Col>
        <Col span={24} lg={12}>
          <label className="d-block mb-1">
            Identifiant <span className="text-danger">*</span>
          </label>
          <Input
            id="username"
            type="text"
            size="large"
            autoFocus
            defaultValue={currentUser?.username}
            placeholder="Identifiant"
            required
            onChange={(e) => {
              updateUser(e)
            }}
          />
        </Col>
        <Col span={24} lg={12}>
          <label className="d-block mb-1">
            Rôle <span className="text-danger">*</span>
          </label>
          <Select
            id="role"
            size="large"
            className='w-100'
            loading={isLoading}
            options={roles?.map((role: any) => {return {value: role.NomDuRole, label: role.NomDuRole}})}
            defaultValue={currentUser?.roles}
            onChange={(value) => updateUser({target: {id: 'roles', value}})}
          />
        </Col>
        <Col span={24}>
          <label className="d-block mb-1">
            Email <span className="text-danger">*</span>
          </label>
          <div className="p-3 opacity-50 rounded-1 bg-black-1">{ currentUser?.email ? currentUser?.email : 'Email non renseigné' }</div>
        </Col>
        <Col span={24}>
          <label className="d-block mb-1">
            Mot de passe <span className="text-danger">*</span>
          </label>
          <Input.Password
            id="password"
            type="password"
            size="large"
            placeholder="Mot de passe"
            required
            onChange={(e) => {
              updateUser(e)
              setIsPasswordValid(validatePassword(e.target.value))
            }}
          />
          {
            !isPasswordValid && !disablePassword && (
              <div className="p-1 ps-2 small text-danger">
                Le mot de passe doit contenir au moins 8 caractères, dont au moins
                une majuscule, un chiffre et un caractère spécial.
              </div>
            )
          }
        </Col>
       
        <Col span={24} className="text-center">
          <Space>
            <Button
              danger
              type="dashed"
              size="large"
              onClick={handleModalClose}
              style={{ marginLeft: '8px' }}
            >
              Annuler
            </Button>
            <Button size="large" loading={isLoading} onClick={handleSave}>
              Enregistrer
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  )
}

export default EditProfil
