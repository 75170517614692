import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, Input, Button, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import {AuthStoreInstance} from '../../helpers/Auth'

function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    if (AuthStoreInstance.isAuthenticated)
      navigate('/')

    document.body.classList.add('bg-linear-6')
    return () => document.body.classList.remove('bg-linear-6')
  }, [])

  const handleUsernameChange = (e: any) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    try {
      await AuthStoreInstance.authenticate({login: username, password})
      // Vérifiez si l'utilisateur est actif
      if (AuthStoreInstance.authData?.user?.active == false) {
        setErrorMessage(
          "Votre compte n'est pas actif. Veuillez contacter l'administrateur."
        )
        return
      }

      // Vérifiez si la réponse contient un token valide
      if (AuthStoreInstance.isAuthenticated) {
        window.location.href = '/'
      } else {
        // Authentification incorrecte, affichez un message d'erreur
        setErrorMessage("Nom d'utilisateur ou mot de passe est incorrect.")
      }
    } catch (error) {
      // Erreur réseau ou autre erreur, affichez un message d'erreur générique
      console.error('Login failed:', error)
      setErrorMessage("Une erreur s'est produite lors de la connexion.")
    }
  }

  return (
    <main>
      <section className='min-hv-100 d-flex flex-column align-items-center justify-content-center'>
        <div className='w-100 mwpx-400'>
          <Card>
            <form onSubmit={handleSubmit}>
              <Space size='middle' direction='vertical' className='w-100'>
                <h1 className='my-0 fs-3 text-center'>Connexion</h1>

                <Input
                  required
                  value={username}
                  onChange={handleUsernameChange}
                  size="large"
                  placeholder="username"
                />

                <Input.Password
                  required
                  value={password}
                  onChange={handlePasswordChange}
                  size="large"
                  placeholder="password"
                />

                <Button
                  size='large'
                  htmlType="submit"
                  className='w-100'
                  onClick={(e) => {
                    handleSubmit(e)
                  }}
                  loading={false}
                >
                  Valider
                </Button>

                {
                  errorMessage && <div className='text-danger'><FontAwesomeIcon icon={faTriangleExclamation} className='me-2' />{ errorMessage }</div>
                }
              </Space>
            </form>
          </Card>
        </div>
      </section>
    </main>
  )
}

export default Login
