import { useState, useEffect } from 'react';

import { Space, Row, Col, Modal, Button, Input, Form, Tree } from 'antd';
import { getRoles } from '../../querys/userQuery';
import ApiRequest from '../../helpers/ApiRequest';
import { ENDPOINTS } from '../../constants/api';


interface Props {
  isModalOpen: boolean;
  handleModalClose: () => void;
  roles: string[];
  setRoles: (roles: string[]) => void;
}

function ModalRole({ isModalOpen, handleModalClose, setRoles, roles }: Props) {
  const { TextArea } = Input

  const [treeData, setTreeData] = useState<any>(null); // Changement: Utiliser null au lieu de false pour treeData initial
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rolesData = await getRoles();
        setTreeData(rolesData); // Changement: Stocker directement les données dans treeData sans objet formaté
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      const roleResponse: any = ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.checkRoles.replace(':NomDuRole', form.getFieldValue('NomDuRole'))})
      if (roleResponse.data.exists) {
        form.setFields([
          {
            name: 'NomDuRole',
            errors: ['Ce rôle existe déjà. Veuillez en choisir un autre.'],
          },
        ]);
        return;
      }

      const formData = form.getFieldsValue();
      formData.roles = selectedRoles;
      const response: any = await ApiRequest.request({data: formData, method: 'POST', endpoint: ENDPOINTS.createRole});
      setRoles([...roles, response.data]);
      handleModalClose();
    } catch (error) {
      console.error('Erreur de validation du formulaire:', error);
    }
  };

  const modalFooter = (
    <Space>
      <Button danger type='dashed' size='large' onClick={handleModalClose}>
        Annuler
      </Button>

      <Button size='large' onClick={handleSubmit}>
        Valider
      </Button>
    </Space>
  );

  return (
    <Modal
      centered
      open={isModalOpen} // Changement: Utiliser "visible" au lieu de "open" pour contrôler la visibilité
      onCancel={handleModalClose}
      footer={modalFooter}
      closable={false}
    >
      <Form form={form} onFinish={handleSubmit}>
        {/* todo // voir props pour le titre fonctionne puis Role.tsx */}
        {/* { props.title && <div className='mb-5 fw-bold fs-5 text-center'>{ props.title }</div> } */}

        <Row gutter={[16, 24]}>
          <Col span={24}>
            <label className='d-block mb-1'>Titre <span className='text-danger'>*</span></label>
            <Form.Item
              className='mb-0'
              name="NomDuRole"
              rules={[
                { required: true, message: 'Veuillez entrer le Nom de Groupe' },
              ]}
            >
              <Input size='large' autoFocus />
            </Form.Item>
          </Col>
          <Col span={24}>
            <label className='d-block mb-1'>Description</label>
            <Form.Item
              className='mb-0'
              name="DescriptionDuRole"
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <label className='d-block mb-1'>Roles</label>
            <Form.Item name="roles" className='mb-0'>
              {
                treeData && (
                  <Tree
                    checkable
                    treeData={treeData}
                    onCheck={(checkedKeys: any) => setSelectedRoles(checkedKeys)}
                  />
                )
              }
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ModalRole;
