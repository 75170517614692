import { useState, useEffect } from 'react'

import axios from 'axios'

import { Modal, Button, Row, Col, Input, Form, notification, Space } from 'antd'
import ApiRequest from '../../helpers/ApiRequest'
import { ENDPOINTS } from '../../constants/api'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

function ModalInvite(props: any) {
  const { isInviteModalOpen, setIsInviteModalOpen } = props
  const [form] = Form.useForm()

  const [submittable, setSubmittable] = useState(false)

  // Watch all values
  const values = Form.useWatch([], form)

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(false)
      },
      () => {
        setSubmittable(true)
      }
    )
  }, [values])

  const handleCancel = () => {
    setIsInviteModalOpen(false)
  }

  const handleInvite = async () => {
    try {
      await form.validateFields()

      const email = form.getFieldValue('email')

      // Check if the email exists
      const emailResponse: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.inviteUserCheckEmail.replace(':email', email)})
      if (emailResponse.data.exists) {
        form.setFields([
          {
            name: 'email',
            errors: [
              'Cet email est déjà utilisé. Veuillez en choisir un autre.',
            ],
          },
        ])
        return
      }
      await ApiRequest.request({data : {email}, method: 'POST', endpoint: ENDPOINTS.inviteUser})

      // Display a success notification
      notification.success({
        message: 'Email envoyé avec succès',
        description: `L'email à ${email} a été envoyé avec succès.`,
      })

      setIsInviteModalOpen(false)
    } catch (error) {
      console.error('Erreur de validation du formulaire :', error)
    }
  }

  const modalFooter = () => {
    return (
      <Space>
        <Button danger type="dashed" size="large" onClick={handleCancel}>
          Annuler
        </Button>

        {/* todo /: si les champs ne sont pas rempli alors disabled */}
        <Button size="large" disabled={submittable} onClick={handleInvite}>
          Valider
        </Button>
      </Space>
    )
  }

  return (
    <Modal
      centered
      open={isInviteModalOpen}
      onCancel={handleCancel}
      footer={modalFooter()}
      closable={false}
    >
      <Form form={form}>
        {props.title && (
          <div className="mb-5 fw-bold fs-5 text-center">{props.title}</div>
        )}

        <Row gutter={[16, 24]}>
          <Col span={24}>
            <label className="d-block mb-1">
              Adresse e-mail <span className="text-danger">*</span>
            </label>
            <Form.Item
              className="mb-0"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir une adresse e-mail',
                },
                {
                  type: 'email',
                  message: 'Veuillez saisir une adresse e-mail valide',
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ModalInvite
