import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Space, Avatar, Dropdown, Menu, Drawer } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog,
  faSignOutAlt,
  faUserTie,
  faUsers,
  faUserSecret,
  faFolderTree,
} from '@fortawesome/free-solid-svg-icons'
import { AuthStoreInstance } from '../../helpers/Auth'
import axios from 'axios'
import EditProfil from '../UsersComponents/EditProfil'

function Header() {
  const [isDrawerVisible, setDrawerVisible] = useState(false)
  const [user, setUser] = useState<any>();
  const location = useLocation()
  const isProduitsActive = location.pathname === '/'
  const isUsersActive = location.pathname === '/users'
  const isRolesActive = location.pathname === '/roles'
  const authToken = localStorage.getItem('accessToken')

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })
  // Changer les coleur de dashboard
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`users/token?token=` + authToken)
        const { style_background, button_color } = response.data
        console.log(response.data)
        setUser(response.data)
        addThemeClass(style_background)
        addThemeClass(button_color)
      } catch (error) {
        console.error('Erreur lors de la récupération des rôles :', error)
      }
    }

    const addThemeClass = (style: any) => {
      if (style) {
        document.body.classList.add(`theme-${style}`)
      }
    }

    // Appelez fetchData pour effectuer la requête API
    fetchData()
  }, [authToken])

  const showDrawer = () => {
    setDrawerVisible(true)
  }

  const onCloseDrawer = () => {
    setDrawerVisible(false)
  }

  const handleLogout = () => {
    AuthStoreInstance.clearAuth()
  }
  console.log(AuthStoreInstance.authData)

  const menu = (
    <Menu>
      <Menu.Item key="setting" onClick={showDrawer}>
        <FontAwesomeIcon icon={faCog} className="me-2" />
        Profil
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
        Déconnexion
      </Menu.Item>
    </Menu>
  )

  return (
    <header className="header">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between p-2">
          {isProduitsActive ? (
            <div className="navbar-brand">ADMIN</div>
          ) : (
            <Link to="/" className="navbar-brand">
              ADMIN
            </Link>
          )}

          <Space size={40} className="w-100 justify-content-center text-center">
            <Link
              to="/"
              className={'link-item' + (isProduitsActive ? ' active' : '')}
            >
              <FontAwesomeIcon icon={faFolderTree} />
              <div className="mt-1 text-small">Produits</div>
            </Link>

            <Link
              to="/users"
              className={'link-item' + (isUsersActive ? ' active' : '')}
            >
              <FontAwesomeIcon icon={faUsers} />
              <div className="mt-1 text-small">Utilisateurs</div>
            </Link>

            <Link
              to="/roles"
              className={'link-item' + (isRolesActive ? ' active' : '')}
            >
              <FontAwesomeIcon icon={faUserSecret} />
              <div className="mt-1 text-small">Rôles</div>
            </Link>
          </Space>

          <div>
            <Dropdown placement="bottomRight" className="cursor" overlay={menu}>
              <Avatar icon={<FontAwesomeIcon icon={faUserTie} />} />
            </Dropdown>
          </div>
        </div>
      </div>

      <Drawer
        width={450}
        title="Modifier mon profil"
        placement="right"
        closable={false}
        onClose={onCloseDrawer}
        open={isDrawerVisible}
      >
        {isDrawerVisible && (
          <EditProfil
            handleModalClose={onCloseDrawer}
            onSave={onCloseDrawer}
            user={user}
          />
        )}
      </Drawer>
    </header>
  )
}

export default Header
