import { useState } from 'react'
import { useNavigate, useLoaderData } from 'react-router-dom'

import { Card, Button, Space, Empty, Row, Col, Progress } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@fortawesome/free-solid-svg-icons'

import ModalProduit from '../../components/ProduitComponents/ModalProduit'
import Produit from '../../components/ProduitComponents/Produit'
import ApiRequest from '../../helpers/ApiRequest'
import { ENDPOINTS } from '../../constants/api'



function Produits() {
  const products: any = useLoaderData()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [reverse, setReverse] = useState(true)
  const navigate = useNavigate();
  
  const handleClick = () => {
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleValidClick = async (newProduct: any) => {
    try {
      const response: any = await ApiRequest.request({data: newProduct, method: 'POST', endpoint: ENDPOINTS.addProduct})
      navigate(`/widgets/${response.data.id}`)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <main>
        <div className="container">
          <Card className="mt-5 text-center border-linear">
            <Row justify="center" align="middle">
              <Col lg={12}>
                <h1 className="my-0">Produits</h1>
              </Col>

              <Col lg={12}>
                <Space size="large">
                  <div>
                    <Progress
                      type="dashboard"
                      size="small"
                      percent={100}
                      format={() => products.length}
                    />
                    <div className="fw-bold">Produits</div>
                  </div>
                </Space>
              </Col>
            </Row>
          </Card>

          <Space size='middle' className='w-100 justify-content-end mt-10'>
            <Button size='large' onClick={handleClick}>
              <FontAwesomeIcon icon={faAdd} className='me-2' /> Ajouter
            </Button>
          </Space>

          {
            !products ?
              <div className='hpx-200 d-flex align-items-center justify-content-center text-center'><Empty /></div>
            :
              <Card className='mt-3 p-2'><Produit products={products} reverse={reverse} /></Card>
          }

        </div>
      </main>

      {
        isModalOpen &&
        (
          <ModalProduit
            title='Ajouter un produit'
            isModalOpen={isModalOpen}
            handleModalClose={handleModalClose}
            handleValidClick={handleValidClick}
          />
        )
      }
    </>
  )
}

export default Produits
