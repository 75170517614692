import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { API_BASENAME, ENDPOINTS } from '../constants/api';
import interceptorInjector from './Interceptor';
import { AuthData, AuthStoreInstance } from './Auth';
import { LOCAL_STORAGE } from '../constants/localStorage';

type RequestConfig = {
    endpoint: string;
    method: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
    data: Record<string, string | number | null>;
    skipHeaders?: boolean;
};

class ApiRequest {
    apiInstance: AxiosInstance;

    constructor() {
        this.apiInstance = axios.create({
            baseURL: API_BASENAME,
        });
        // Tricky fix! Handle multiply requests now
        interceptorInjector<AuthData['token']>(this.apiInstance, {
            shouldIntercept: (error: AxiosError) => {
                try {
                    return error?.response?.status === 403;
                } catch (e) {
                    return false;
                }
            },
            setTokenData: (authData) => {
                localStorage.setItem(LOCAL_STORAGE.ACCESS, authData);
                localStorage.setItem(LOCAL_STORAGE.REFRESH, authData);

                if (AuthStoreInstance.authData) {
                    AuthStoreInstance.authData.token = authData;
                }
            },
            attachTokenToRequest: (request: any) => {
                request.headers.bearer = localStorage.getItem(LOCAL_STORAGE.ACCESS) || '';
            },
            handleTokenRefresh: () => {
                return new Promise<AuthData['token']>((res, rej) => {
                    this.request<AuthData['token']>({
                        endpoint: ENDPOINTS.REFRESH,
                        method: 'POST',
                        data: {
                            accessToken: localStorage.getItem(LOCAL_STORAGE.ACCESS),
                            refreshToken: localStorage.getItem(LOCAL_STORAGE.REFRESH),
                        },
                        skipHeaders: true,
                    })
                        .then((response) => {
                            res(response.data);
                        })
                        .catch((err) => {
                            if (err.response.status === 401) {
                                AuthStoreInstance.clearAuth();
                            }
                            rej(err);
                        });
                });
            },
        });
    }

    // eslint-disable-next-line class-methods-use-this
    request<Response>({ data = {}, method = 'GET', endpoint = '', skipHeaders = false }: RequestConfig): Promise<AxiosResponse<Response>> {
        const requestData = method === 'GET' || method === 'DELETE' ? { params: data } : { data };
        const headers = skipHeaders ? {} : { bearer: localStorage.getItem(LOCAL_STORAGE.ACCESS) || '' };

        return this.apiInstance.request<Response>({
            method,
            baseURL: API_BASENAME,
            url: endpoint,
            ...requestData,
            headers,
        });
    }
}

export default new ApiRequest();