import { useState } from 'react'
import { Row, Col, Modal, Space, Button, Input, Select } from 'antd'
import { getAllProcutsOptions } from '../../querys/userQuery'


function EtatsModal(props: any) {
  const { TextArea } = Input

  const {
    isModalOpen,
    handleModalClose,
    handleValidClick,
  } = props

  const [name, setName] = useState(props.name || '')
  const [description, setDescription] = useState(props.description || '')
  const [selectedProduct, setSelectedProduct] = useState<string>('')
  const {data, isLoading}: any = getAllProcutsOptions()

  const modalFooter = () => {
    return (
      <Space>
        <Button danger type='dashed' size='large' onClick={handleModalClose}>Annuler</Button>

        <Button size='large' onClick={() => handleValidClick({ nom: name, description: description, selectedProduct: selectedProduct })} disabled={name.length < 1}>
          Valider
        </Button>
      </Space>
    )
  }

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleModalClose}
      closable={false}
      footer={modalFooter()}
      destroyOnClose
    >
      { props.title && <div className='mb-2 fw-bold fs-5 text-center'>{ props.title }</div> }

      <Row gutter={[16, 24]}>
        <Col span={24}>
          <label className='d-block mb-1'>Nom <span className='text-danger'>*</span></label>
          <Input
            id="name"
            type="nom"
            size='large'
            autoFocus
            value={name}
            required
            onChange={(e) => {setName(e.target.value)}}
          />
        </Col>
        <Col span={24}>
          <label className='d-block mb-1'>Produits <span className='text-danger'>*</span></label>
          <Select
            size='large'
            className='w-100'
            showSearch
            loading={isLoading}
            options={data}
            onChange={(value: string) => setSelectedProduct(value)}
          />
        </Col>
        <Col span={24}>
          <label className='d-block mb-1'>Description</label>
          <TextArea
            id="outlined-multiline-static"
            rows={4}
            autoFocus
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default EtatsModal
