import { useState } from 'react'
import { Modal, Button, Input, Row, Col, Space, Switch } from 'antd'


function ModalProduit(props: any) {
  const { TextArea } = Input

  const {
    isModalOpen,
    handleModalClose,
    handleValidClick,
  } = props

  const [label, setLabel] = useState(props.label || props.product?.label || '')
  const [note, setNote] = useState(props.note || props.product?.note || '')

  const modalFooter = () => {
    return (
      <Space>
        <Button danger type='dashed' size='large' onClick={handleModalClose}>Annuler</Button>

        <Button size='large' onClick={() => handleValidClick({ label: label, note: note })} disabled={label.length < 1}>
          Valider
        </Button>
      </Space>
    )
  }

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleModalClose}
      closable={false}
      footer={modalFooter()}
    >
      { props.title && <div className='mb-2 fw-bold fs-5 text-center'>{ props.title }</div> }

      <Row gutter={[16, 24]}>
        <Col span={24}>
          <label className='d-block mb-1'>Titre <span className='text-danger'>*</span></label>
          <Input
            id="name"
            type="label"
            size='large'
            autoFocus
            value={label}
            required
            onChange={(e) => setLabel(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <label className='d-block mb-1'>Note</label>
          <TextArea
            id="outlined-multiline-static"
            rows={4}
            autoFocus
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <div className='d-flex align-items-center justify-content-between p-3 bg-default-1 rounded-2'>
            <span>Désactiver le produit</span> <Switch checked />
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default ModalProduit
