export const API_BASENAME = process.env.REACT_APP_API_URL
export const ENDPOINTS = {
  REFRESH: '',
  AUTH: '/users/login',
  getAllProcutsRequest: '/produits',
  getUserDataRequest: '/users/data',
  getAllRoles: '/role/groupe',
  getAllProductsListRequest: '/produits',
  addProduct: '/produits',
  getProduct: '/produits/:id',
  getAllProdcutWidgets: '/produits/:id/widgets',
  getAllProductEtats: '/data/produits/:id/etats',
  getGroupedChamps: '/widgets/:ids/grouped',
  addWidgets: '/widgets',
  updateProduct: '/produits',
  addEtats: '/data/post',
  getAllWidgetsChamps: '/widgets/:id',
  getAllRelatedChamps: '/widgets/relatedWidgets/:id/champs',
  savePreview: '/widgets/update-preview',
  saveWidgets: 'widgets/:id',
  getEtat: '/data/:id/etats',
  saveEtat: '/data/:id/etats',
  getAllUsers: '/users',
  updateWidget: '/widgets/:id',
  updateUser: '/users/:id',
  updateState: '/users/state/:id',
  getUserInviteToken: '/users/token/?token=:token',
  inviteUserCheckEmail: '/users/check-email?email=:email',
  createuser: '/users/create',
  inviteUser: '/users/invite',
  getRoles: '/role',
  checkRoles: '/role/check-role?NomDuRole=:NomDuRole',
  createRole: '/role',
  putRole: '/role/:roleId'
}


