import { useQuery } from 'react-query'
import ApiRequest from "../helpers/ApiRequest"
import keys from './keys'
import { ENDPOINTS } from '../constants/api'

const getAllProcutsRequest = async () => {
  const response: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getAllProcutsRequest})
  const options = response.data.map((product: any) => ({
    value: product.id,
    label: product.label,
  }))
  return options
}

export const getAllProcutsOptions = () => {
  return useQuery(keys.getProduct, getAllProcutsRequest)
}

export const getUserDataRequest = async () => {
  const response: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getUserDataRequest})
  return response.data
}

export const getUserData = () => {
  return useQuery(keys.getUserData, getUserDataRequest)
}

export const getRoles = async () => {
  const response: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getRoles})
  return response.data.map((role: any, index: any) => {
    return {...role, key: index}
  })
}


export const getAllRoles = async () => {
  const response: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getAllRoles})
  return response.data
}

export const getAllRolesData = () => {
  return useQuery(keys.getRoles, getAllRoles)
}

/*const getAllProductsListRequest = async () => {
  const response: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getAllProductsListRequest})
  return response.data
}*/

export const getAllProductsList = async () => {
  const response: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getAllProductsListRequest})
  return response.data
}

export const getAllWidgetsChamps = async (req: any) => {
  const response: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getAllWidgetsChamps.replace(':id', req.params.id)})
  const relatedChamps: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getAllRelatedChamps.replace(':id', req.params.id)})
  response.data.relatedChamps = relatedChamps.data
  return response.data
}

export const getProductData = async (req: any) => {
  const responseProducts: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getProduct.replace(':id', req.params.id)})
  const responseWidgets: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getAllProdcutWidgets.replace(':id', req.params.id)})
  const responseEtats: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getAllProductEtats.replace(':id', req.params.id)})
  const options: any = []
  responseEtats.data.map((product: any) => {
    options.push({
      value: product.id,
      label: product.nom,
      onClick: () => {
        return window.location.href = `/etats/${product.id}`
      }
    })
})
  return { product: responseProducts.data[0], widgets: responseWidgets.data, etatsOptions: options }
}

export const getEtatDate =async (req:any) => {
  const responseEtats: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getEtat.replace(':id', req.params.id)})
  const etatsData = responseEtats.data.map((item: any) => item.etat)
  etatsData[0] = typeof etatsData[0] === 'string' ? JSON.parse(etatsData[0]) : etatsData[0]
  const productId = responseEtats.data[0].Produit_id
  const responseProductWidgets: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getAllProdcutWidgets.replace(':id', productId)})
  const widgetsData = responseProductWidgets.data.map((item: any) => {
    item.settings = typeof item.settings === 'string' ? JSON.parse(item.settings) : item.settings
    return {
    id: item.id,
    nom: item.nom,
    settings: item.settings.flat(),
    selectedCalculation: null,
  }})
  const etatsSettings: any = []
  widgetsData.map((w: any) => {
    w.settings = typeof w.settings === 'string' ? JSON.parse(w.settings) : w.settings
    w.settings.flat().filter((w: any) => w.element === 'NumberInput').map((s: any) => {
      const currntInputState = etatsData[0]?.find((e: any) => e.input_id === s.id)
      etatsSettings.push({
        widget_id: w.id,
        input_id: s.id,
        selectedCalculation: currntInputState?.selectedCalculation
      })
    })
  })
  const responseProduct: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getProduct.replace(':id', productId)})
  return {etat: responseEtats.data[0], product: responseProduct.data[0], etats: etatsSettings, saveData: etatsSettings, widgets: widgetsData}
}

export const getAllUsers = async () => {
  const response: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getAllUsers})
  return response.data 
}

export const getUserInviteInfo = async (req:any) => {
  const {data}: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getUserInviteToken.replace(':token', req.params.token)})
  return {emailFromToken: data.email, idUser: data.id, token: data.token}
}