const colorPalettes = [
  {
    name: 'default',
    fill: '',
    class: ''
  },
  {
    name: 'green',
    fill: '#00ba7c',
    class: 'green'
  },
  {
    name: 'orange',
    fill: '#ff7a00',
    class: 'orange'
  },
  {
    name: 'pink',
    fill: '#f91880',
    class: 'pink'
  },
  {
    name: 'yellow',
    fill: '#ffd400',
    class: 'yellow'
  },
]

export default colorPalettes
