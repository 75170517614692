import { createContext } from 'react';
import { observable, computed, action } from 'mobx';
import axios, { AxiosError } from 'axios';
import { API_BASENAME, ENDPOINTS } from '../constants/api';
import { LOCAL_STORAGE } from '../constants/localStorage';

export interface AuthData {
    token: string;
    user: any;
}

export interface Credentials {
    login: string;
    password: string;
}

class AuthStore {
    constructor() {
        const authData = localStorage.getItem(LOCAL_STORAGE.AUTH);
        if (authData !== null) {
            this.authData = JSON.parse(authData);
        }
    }

    @action.bound
    async authenticate(credentials: Credentials): Promise<void> {
        try {
            this.pending = true;
            const { data } = await axios.post<AuthData>(`${API_BASENAME}${ENDPOINTS.AUTH}`, credentials);
            localStorage.setItem(LOCAL_STORAGE.ACCESS, data.token);
            localStorage.setItem(LOCAL_STORAGE.REFRESH, data.token);
            localStorage.setItem(LOCAL_STORAGE.AUTH, JSON.stringify(data.user));
            this.authData = data;
        } catch (error) {
            this.error = error as AxiosError;
        } finally {
            this.pending = false;
        }
    }

    @action.bound
    clearAuth(): void {
        localStorage.removeItem(LOCAL_STORAGE.AUTH);
        localStorage.removeItem(LOCAL_STORAGE.ACCESS);
        localStorage.removeItem(LOCAL_STORAGE.REFRESH);
        this.authData = undefined;
        window.location.href = '/login'

    }

    @observable
    pending = false;

    @observable
    error!: AxiosError;

    @observable
    authData: AuthData | undefined;

    @computed
    get isAuthenticated(): boolean {
        return this.authData !== undefined;
    }
}

export const AuthStoreInstance = new AuthStore();
export default createContext(AuthStoreInstance);