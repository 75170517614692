import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Table, Badge, Tooltip } from 'antd'


interface Props {
  products: any[]
  reverse: boolean
}

const Produit: React.FC<Props> = ({ products, reverse }) => {
  const navigate = useNavigate()
  let productList = []

  if (reverse) {
    productList = products?.slice(0).reverse()
  } else {
    productList = products
  }

  interface Product {
    id: number;
    titre: string;
    note: string;
    active: string;
  }

  const dataTable = productList.map((product) => ({
    id: product.id,
    titre: product.label,
    note: product.note,
    active: product.active
  }))

  const columns = [
    {
      title: 'Titre',
      dataIndex: 'titre',
      className: 'fw-bold',
      sorter: (a: Product, b: Product) => a.titre.localeCompare(b.titre),
      showSorterTooltip: false
    },
    {
      title: 'Note',
      dataIndex: 'note',
      render: (text: any, record: Product)=> (
        <Tooltip placement="topLeft" arrow={false} title={record.note}>
          <div className='ellipsis-7'>{ record.note }</div>
        </Tooltip>
      )
    },
    {
      title: 'Statut',
      dataIndex: 'statut',
      width: 120,
      render: (text: any, record: Product)=> (
        <Badge status={record.active ? 'success' : 'error'} text={record.active ? 'Activé' : 'Désactivé'} />
      )
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={dataTable}
      pagination={ dataTable.length > 15 ? { pageSize: 15 } : false }
      rowKey='id'
      rowClassName={() => 'cursor'}
      onRow={(record: Product) => ({onClick: () => navigate('/widgets/' + record.id)})}
    />
  )
}

export default Produit
