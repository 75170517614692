import { Card, Button, Table, Space, Empty, Row, Col, Progress } from 'antd'
import { useState } from 'react'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit } from '@fortawesome/free-solid-svg-icons'

import ModalRole from '../../components/RoleComponents/ModalRole'
import ModalEdit from '../../components/RoleComponents/ModalEdit'


function Role() {
  const roles: any = useLoaderData()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRoles, setSelectedRoles] = useState<any | null>(null)
  const revalidate = useRevalidator()
  interface Product {
    NomDuRole: string;
    DescriptionDuRole: string;
    action: string;
  }

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }

  const handleEdit = (role: any) => {
    setSelectedRoles(role)
  }

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'NomDuRole',
      key: 'NomDuRole',
    },
    {
      title: 'Description',
      dataIndex: 'DescriptionDuRole',
      key: 'DescriptionDuRole',
      render: (text: any, record: Product)=> (
        <div className='ellipsis-7'>{ record.DescriptionDuRole }</div>
      )
    },
    {
      title: '-',
      key: 'action',
      width: 40,
      className: 'text-center',
      render: (record: any) => (
        <Space size='middle' className='w-100 justify-content-end'>
          <Button shape='circle' onClick={() => handleEdit(record)}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <>
      <main>
        <div className='container'>
          <Card className="mt-5 text-center border-linear">
            <Row justify="center" align="middle">
              <Col lg={12}>
                <h1 className="my-0">Rôles</h1>
              </Col>

              <Col lg={12}>
                <Space size="large">
                  <div>
                    <Progress
                      type="dashboard"
                      size="small"
                      percent={100}
                      format={() => roles.length}
                    />
                    <div className="fw-bold">Rôles</div>
                  </div>
                </Space>
              </Col>
            </Row>
          </Card>

          <Space size='middle' className='w-100 justify-content-end mt-10'>
            <Button size='large' onClick={handleModalOpen}>
              <FontAwesomeIcon icon={faAdd} className='me-2' /> Groupe Role
            </Button>
          </Space>

          <Card className='mt-3 p-2'>
            {
              roles.length === 0 ?
                <div className='hpx-200 d-flex align-items-center justify-content-center text-center'><Empty /></div>
              :
                <Table
                  columns={columns}
                  dataSource={roles}
                  pagination={ roles.length > 15 ? { pageSize: 15 } : false }
                  rowKey="ID"
                />
            }
          </Card>
        </div>
      </main>

      <ModalRole
        // title='Ajouter un groupe de role'
        isModalOpen={isModalOpen}
        handleModalClose={() => setIsModalOpen(false)}
        setRoles={() => {
          revalidate.revalidate()
        }}
        roles={roles}
      />

      {
        selectedRoles && (
          <ModalEdit
            // title='Modifier le Role'
            roles={roles}
            setRoles={() => {
              revalidate.revalidate()
            }}
            selectedRoles={selectedRoles}
            isModalEditOpen={true}
            handleModalClose={() => setSelectedRoles(null)}
          />
        )
      }
    </>
  )
}

export default Role
