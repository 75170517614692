import { useState, useEffect } from 'react'

import { Row, Col, Space, Modal, Button, Input, Tree } from 'antd'
import ApiRequest from '../../helpers/ApiRequest'
import { ENDPOINTS } from '../../constants/api'


function ModalEdit(props: any) {
  const { isModalEditOpen, handleModalClose, selectedRoles } = props

  const { TextArea } = Input

  const [nomDuRole, setNomDuRole] = useState(selectedRoles?.NomDuRole || '')
  const [descriptionDuRole, setDescriptionDuRole] = useState(
    selectedRoles?.DescriptionDuRole || ''
  )
  const [roles, setRoles] = useState(selectedRoles?.roles || [])
  const [treeData, setTreeData] = useState<any>(false)

  useEffect(() => {
    setNomDuRole(selectedRoles?.NomDuRole || '')
    setDescriptionDuRole(selectedRoles?.DescriptionDuRole || '')

    const fetchData = async () => {
      try {
        const response = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.getRoles})
        const rolesData = response.data
        setTreeData(rolesData)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [selectedRoles])

  const handleSubmit = async () => {
    const formData = {
      NomDuRole: nomDuRole,
      DescriptionDuRole: descriptionDuRole,
      roles: JSON.stringify(roles),
    }
    await ApiRequest.request({data: formData, method: 'PUT', endpoint: ENDPOINTS.putRole.replace(':roleId', selectedRoles?.id)})
    console.log(formData)
    const updatedRoles: any = []
    props.roles.map((role: any) => {
      if (role.id === selectedRoles.id) {
        role = {
          id: selectedRoles.id,
          NomDuRole: nomDuRole,
          DescriptionDuRole: descriptionDuRole,
          roles: JSON.stringify(roles),
        }
      }
      updatedRoles.push(role)
    })
    props.setRoles(updatedRoles)
    handleModalClose()
  }

  const modalFooter = () => {
    return (
      <Space>
        <Button danger type='dashed' size='large' onClick={handleModalClose}>
          Annuler
        </Button>

        <Button size='large' onClick={handleSubmit}>
          Valider
        </Button>
      </Space>
    )
  }

  const getTreeData = () => {
    return (
      <Tree
        checkable
        defaultCheckedKeys={
          selectedRoles.roles ? JSON.parse(selectedRoles.roles) : []
        }
        treeData={treeData}
        onCheck={(checkedKeys) => setRoles(checkedKeys)}
      />
    )
  }

  return (
    <Modal
      centered
      open={isModalEditOpen}
      onCancel={handleModalClose}
      footer={modalFooter()}
      closable={false}
    >
      { props.title && <div className='mb-5 fw-bold fs-5 text-center'>{ props.title }</div> }

      <Row gutter={[16, 24]}>
        <Col span={24}>
          <label className='d-block mb-1'>Titre <span className='text-danger'>*</span></label>
          <Input
            autoFocus
            size='large'
            id="NomDuRole"
            type="text"
            required
            value={nomDuRole}
            onChange={(e) => setNomDuRole(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <label className='d-block mb-1'>Description</label>
          <TextArea
            autoFocus
            id="DescriptionDuRole"
            rows={4}
            size='large'
            required
            value={descriptionDuRole}
            onChange={(e) => setDescriptionDuRole(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <label className='d-block mb-1'>Roles</label>
          { treeData && getTreeData() }
        </Col>
      </Row>
    </Modal>
  )
}

export default ModalEdit
