const colorPalettes = [
  {
    name: 'default',
    fill: '',
    class: ''
  },
  {
    name: 'corail',
    fill: '#faafa8',
    class: 'corail'
  },
  {
    name: 'peche',
    fill: '#f39f76',
    class: 'peche'
  },
  {
    name: 'sable',
    fill: '#fff8b8',
    class: 'sable'
  },
  {
    name: 'menthe',
    fill: '#e2f6d3',
    class: 'menthe'
  },
  {
    name: 'sauge',
    fill: '#b4ddd3',
    class: 'sauge'
  },
  {
    name: 'brume',
    fill: '#d4e4ed',
    class: 'brume'
  },
  {
    name: 'crepuscule',
    fill: '#aeccdc',
    class: 'crepuscule'
  },
]

export default colorPalettes
