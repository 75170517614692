import { useState } from 'react'
import { useLoaderData, useRevalidator } from 'react-router-dom'

import {
  Card,
  Row,
  Col,
  Table,
  Button,
  Switch,
  Space,
  Progress,
  Tooltip,
  Empty,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faEdit, faXmark } from '@fortawesome/free-solid-svg-icons'

import ModalUser from '../../components/UsersComponents/ModalUser'
import ModalEdit from '../../components/UsersComponents/ModalEdit'
import ModalInvite from '../../components/UsersComponents/ModalInvite' // Importez le composant ModalInvite
import ApiRequest from '../../helpers/ApiRequest'
import { ENDPOINTS } from '../../constants/api'

function Users() {
  const users: any = useLoaderData()
  const revalidator = useRevalidator()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false) // Nouvel état pour le modal d'invitation
  const [selectedUser, setSelectedUser] = useState<any | null>(null)


  const handleModalOpen = () => {
    setIsModalOpen(true)
  }

  const handleEditClick = (user: any) => {
    setSelectedUser(user)
    setIsModalOpen(false)
  }

  const handleInviteClick = () => {
    setIsInviteModalOpen(true)
  }

  const handleActivateDeactivate = async (
    userId: number | string,
    isActive: boolean | any
  ) => {
    try {
      await ApiRequest.request({data: { id: userId, active: isActive }, method: 'POST', endpoint: ENDPOINTS.updateState.replace(':id', userId.toString())})
      revalidator.revalidate()
    } catch (error) {
      console.error(error)
    }
  }

  interface Users {
    username: string
    nom: string
    prenom: string
    email: string
    roles: string
  }

  const columns = [
    {
      title: 'Identifiant',
      dataIndex: 'username',
      key: 'username',
      className: 'fw-bold',
      render: (record: any) => (
        <>
          {record === '' ? (
            <FontAwesomeIcon
              icon={faXmark}
              size="2x"
              shake
              flip="horizontal"
              color="red"
            />
          ) : (
            record
          )}
        </>
      ),
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
      render: (record: any) => (
        <>
          {record === '' ? (
            <FontAwesomeIcon
              icon={faXmark}
              size="2x"
              shake
              flip="horizontal"
              color="red"
            />
          ) : (
            record
          )}
        </>
      ),
      sorter: (a: Users, b: Users) => a.nom?.localeCompare(b.nom),
      showSorterTooltip: false,
    },
    {
      title: 'Prenom',
      dataIndex: 'prenom',
      key: 'prenom',
      render: (record: any) => (
        <>
          {record === '' ? (
            <FontAwesomeIcon
              icon={faXmark}
              size="2x"
              shake
              flip="horizontal"
              color="red"
            />
          ) : (
            record
          )}
        </>
      ),
      sorter: (a: Users, b: Users) => a.prenom?.localeCompare(b.prenom),
      showSorterTooltip: false,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (record: any) => (
        <Tooltip placement="topLeft" arrow={false} title={record}>
          <div className="ellipsis-7">{record}</div>
        </Tooltip>
      ),
    },
    {
      title: 'Rôle',
      dataIndex: 'roles',
      key: 'roles',
      className: 'text-center',
      render: (record: any) => (
        <>
          {record === null ? (
            <FontAwesomeIcon
              icon={faXmark}
              size="2x"
              shake
              flip="horizontal"
              color="red"
            />
          ) : (
            record
          )}
        </>
      ),
      sorter: (a: Users, b: Users) => a.roles?.localeCompare(b.roles),
      showSorterTooltip: false,
    },
    {
      title: 'Statut',
      key: 'statut',
      className: 'text-center',
      render: (record: any) => (
        <Switch
          checked={record.active}
          onChange={(checked) => handleActivateDeactivate(record.id, checked)}
          disabled={!record.username}
        />
      ),
    },
    {
      title: '-',
      key: 'action',
      width: 40,
      className: 'text-center',
      render: (record: any) => (
        <Space size="middle" className="w-100 justify-content-end">
          <Button shape="circle" onClick={() => handleEditClick(record)}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </Space>
      ),
    },
  ]
  const sortUsers = (users: any) => {
    return users.map((user: any) => { 
      user.key = user.id
      return user
     }).sort((a: any, b: any) => {
      // Mettre en premier les utilisateurs sans identifiant (username vide)
      if (a.username === '' && b.username !== '') {
        return -1
      }
      // Mettre en dernier les utilisateurs sans identifiant (username vide)
      if (a.username !== '' && b.username === '') {
        return 1
      }
      // Pour les autres cas, utilisez l'ordre par défaut
      return a.username?.localeCompare(b.username)
    })
  }
  const sortedUsers = sortUsers(users)

  const activeUsersCount = users?.filter((user: any) => user.isActive).length
  const activeUsersPercentage = (activeUsersCount / users.length) * 100

  return (
    <>
      <main>
        <div className="container">
          <Card className="mt-5 text-center border-linear">
            <Row justify="center" align="middle">
              <Col lg={12}>
                <h1 className="my-0">Utilisateurs</h1>
              </Col>

              <Col lg={12}>
                <Space size="large">
                  <div>
                    <Progress
                      type="dashboard"
                      size="small"
                      percent={100}
                      format={() => users.length}
                    />
                    <div className="fw-bold">Utilisateurs</div>
                  </div>

                  <div className='d-none'>
                    <Progress
                      type="dashboard"
                      size="small"
                      percent={activeUsersPercentage}
                      format={() => activeUsersCount}
                    />
                    <div className="fw-bold">Activés</div>
                  </div>
                </Space>
              </Col>
            </Row>
          </Card>

          <Space size="middle" className="w-100 justify-content-end mt-10">
            <Button size="large" onClick={handleModalOpen}>
              <FontAwesomeIcon icon={faUserPlus} className="me-2" /> Utilisateur
            </Button>

            <Button size="large" onClick={handleInviteClick}>
              <FontAwesomeIcon icon={faUserPlus} className="me-2" /> Inviter
            </Button>
          </Space>

          <Card className="mt-3 p-2">
            {users.length === 0 ? (
              <div className="hpx-200 d-flex align-items-center justify-content-center text-center">
                <Empty />
              </div>
            ) : (
              <Table
                columns={columns}
                dataSource={sortedUsers}
                pagination={sortedUsers.length > 15 ? { pageSize: 15 } : false}
              />
            )}
          </Card>
        </div>
      </main>

      {isModalOpen && (
        <ModalUser
          title="Ajouter un utilisateur"
          users={users}
          setUsers={() => {
            revalidator.revalidate()
          }}
          isModalOpen={isModalOpen}
          handleModalClose={() => setIsModalOpen(false)}
        />
      )}

      {isInviteModalOpen && (
        <ModalInvite
          title="Inviter un utilisateur"
          isInviteModalOpen={isInviteModalOpen}
          setIsInviteModalOpen={setIsInviteModalOpen}
        />
      )}

      {selectedUser && (
        <ModalEdit
          title="Modifier"
          users={users}
          disablePassword={true}
          setUsers={() => {
            revalidator.revalidate()
          }}
          selectedUser={selectedUser}
          isModalEditOpen={true}
          handleModalClose={() => setSelectedUser(null)}
        />
      )}
    </>
  )
}

export default Users
