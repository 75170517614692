import { useState } from 'react'
import { useNavigate, useLoaderData, useRevalidator } from 'react-router-dom'
import axios from 'axios'

import { Row, Col, Space, Badge, Card, Button, Modal, Tabs, TabsProps } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'

import { VisionFormBuilder, VisionForm } from '@sidexia/visionformbuilder'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import ModalWidget from '../../components/WidgetComponents/ModalWidget'
import WidgetsViewLayout from '../../components/WidgetComponents/WidgetsViewLayout'
import ApiRequest from '../../helpers/ApiRequest'
import { ENDPOINTS } from '../../constants/api'


function Champs() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isWidgetModalOpen, setIsWidgetModalOpen] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [fileUrl, setFileUrl] = useState<any>(false)
  const revalidator = useRevalidator()
  const widget: any = useLoaderData()
  widget.settings = typeof widget.settings === 'string' ? JSON.parse(widget.settings) : widget.settings
  widget.form_preview = typeof widget.form_preview === 'string' ? JSON.parse(widget.form_preview) : widget.form_preview

  const [formdata, setFormData] = useState(widget.settings || [])
  const widgetChampsEmail = useState(widget.relatedChamps || [])
  const [previewData, setPreviewData] = useState(widget.form_preview || [])

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })

  const savePreview = async () => {
    try {
      await ApiRequest.request({data: {id : widget.id, form_preview: JSON.stringify(previewData)},method: 'POST', endpoint: ENDPOINTS.savePreview})

    } catch (error) {
      console.error(error)
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  const saveFormulaire = async () => {
    try {
      await ApiRequest.request({data: {
        nom: widget.nom,
        description: widget.description,
        settings: formdata,
        widget_color: widget.widget_color
      }, method: 'PUT', endpoint: ENDPOINTS.saveWidgets.replace(':id', widget.id)})
      await savePreview()
    } catch (error) {
      console.error(error)
    }
  }

  const formSettings = () => {
    return <>
      {
        formdata && (
          <>
            <Space align='end' className='w-100 justify-content-end'>
              <Button size='large' onClick={() => setIsModalOpen(true)}>
                Preview Form
              </Button>
            </Space>

            <Modal
              width={showPreview ? '90%': 520}
              centered
              open={isModalOpen}
              onCancel={handleModalClose}
              closable={false}
              footer={null}
              destroyOnClose
            >
              <Row gutter={[16, 16]}>
                <Col span={showPreview ? 12 : 24}>
                  <VisionForm
                    data={formdata}
                    onSubmit={(values: any) => console.log("form submit values", values)}
                    setShowPreview={setShowPreview}
                    setFileUrl={setFileUrl}
                  />
                </Col>

                {
                  showPreview && (
                    <Col span={12}>
                      <Space className='d-none w-100 justify-content-end'>
                        <Button type="text" shape="circle" size='large' onClick={() => setShowPreview(false)}><FontAwesomeIcon icon={faTimes} /></Button>
                      </Space>

                      <DocViewer
                        config={{
                          header: {
                            disableHeader: true
                          },
                          pdfZoom: {
                            defaultZoom: 1,
                            zoomJump: .5
                          }
                        }}
                        pluginRenderers={DocViewerRenderers}
                        documents={[{ uri: process.env.REACT_APP_STATIC_FILES + fileUrl }]}
                      />
                    </Col>
                  )
                }
              </Row>
            </Modal>
          </>
        )
      }
      <VisionFormBuilder onChange={(form: any) => {setFormData(form) }} initialValue={widget.settings} emailChamps={widgetChampsEmail} />
    </>
  }

  const itemsTabs = [
    {
      label: `Form setting`,
      key: '1',
      children: formSettings(),
    },
    {
      label: `Card layout`,
      key: '2',
      children: <WidgetsViewLayout widgets={formdata} previewData={previewData} setPreviewData={setPreviewData} savePreview={savePreview} />
    }
  ]

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
      <DefaultTabBar {...props} />
  );

  const handleWidgetModalClose = () => {
    setIsWidgetModalOpen(false)
  }

  const handleWidgetValidClick = async (newWidget: any) => {
    await ApiRequest.request({data: newWidget,method: 'PUT', endpoint: ENDPOINTS.updateWidget.replace(':id', widget.id)})
    revalidator.revalidate()
    setIsWidgetModalOpen(false)
  }

  return (
    <>
      <main>
        <div className='container'>
          <Space className='w-100 justify-content-between'>
            <Button size='large' onClick={handleBack}>
              <FontAwesomeIcon icon={faChevronLeft} className='me-2' /> Retour
            </Button>

            <Button size='large' type='primary' onClick={() => {setIsWidgetModalOpen(true)}}>
              <FontAwesomeIcon icon={faEdit} className='me-2' /> Modifier
            </Button>
          </Space>

          <Badge.Ribbon text='Defaut' color='green' className={widget.default ? '' : 'd-none'}>
            <Card className='mt-5 text-center border-linear'>
              <Row justify='center' align='middle'>
                <Col lg={12}>
                  <h1 className='my-0'>{widget.nom}</h1>
                  { widget.description && <div className='mt-5'>{widget.description}</div> }
                </Col>
              </Row>
            </Card>
          </Badge.Ribbon>

          <Space className='w-100 justify-content-end mt-10'>
            <Button size='large' onClick={async () => await saveFormulaire()}>
              Enregistrer
            </Button>
          </Space>

          <Tabs defaultActiveKey="1" centered className='mt-10 pills' renderTabBar={renderTabBar} items={itemsTabs} />
        </div>
      </main>

      {
        isWidgetModalOpen &&
        (
          <ModalWidget
            isModalOpen={isWidgetModalOpen}
            handleModalClose={handleWidgetModalClose}
            handleValidClick={handleWidgetValidClick}
            name={widget.nom}
            isDefault={widget.default}
            description={widget.description}
            widget_color={widget.widget_color}
          />
        )
      }
    </>
  )
}
export default Champs
