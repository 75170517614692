import '../assets/scss/main.scss'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ROOTS } from './roots'
import Layout from './Layout'

const AppNavigation = () => {
  const router = createBrowserRouter([
    {
      element: <Layout/>,
      children: [...ROOTS.PRIVATE, ...ROOTS.PUBLIC]
    }
  ])
  return <RouterProvider router={router} />
}

export default AppNavigation
