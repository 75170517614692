import { Row, Col, Modal, Button, Input, Form, Space, Select } from 'antd';
import { getAllRolesData } from '../../querys/userQuery';
import ApiRequest from '../../helpers/ApiRequest';
import { ENDPOINTS } from '../../constants/api';

function ModalUsers(props: any) {
  const { isModalOpen, handleModalClose } = props;

  const [form] = Form.useForm();
  const {data : roles, isLoading}: any = getAllRolesData()

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      const emailResponse: any = await ApiRequest.request({data: {}, method: 'GET', endpoint: ENDPOINTS.inviteUserCheckEmail.replace(':email', form.getFieldValue('email'))})
      if (emailResponse.data.exists) {
        form.setFields([
          {
            name: 'email',
            errors: [
              'Cet email est déjà utilisé. Veuillez en choisir un autre.',
            ],
          },
        ])
        return
      }

      // Si l'email n'existe pas, procéder à la création de l'utilisateur
      const formData = form.getFieldsValue()
      const response = await ApiRequest.request({data : formData, method: 'POST', endpoint: ENDPOINTS.createuser})
      props.setUsers([...props.users, response.data])
      handleModalClose()
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error);
    }
  };

  const modalFooter = () => {
    return (
      <Space>
        <Button danger type='dashed' size='large' onClick={handleModalClose}>
          Annuler
        </Button>

        <Button size='large' onClick={handleSubmit}>
          Valider
        </Button>
      </Space>
    );
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleModalClose}
      footer={modalFooter()}
      closable={false}
    >
      <Form form={form} onFinish={handleSubmit}>
        { props.title && <div className='mb-5 fw-bold fs-5 text-center'>{ props.title }</div> }

        <Row gutter={[16, 24]}>
          <Col span={24} lg={12}>
            <label className='d-block mb-1'>Nom <span className='text-danger'>*</span></label>
            <Form.Item
              className='mb-0'
              name='nom'
              rules={[{ required: true, message: 'Veuillez entrer le nom' }]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>

          <Col span={24} lg={12}>
            <label className='d-block mb-1'>Prénom <span className='text-danger'>*</span></label>
            <Form.Item
              className='mb-0'
              name='prenom'
              rules={[{ required: true, message: 'Veuillez entrer le prénom' }]}
            >
              <Input autoFocus size='large' />
            </Form.Item>
          </Col>

          <Col span={24} lg={12}>
            <label className='d-block mb-1'>Identifiant <span className='text-danger'>*</span></label>
            <Form.Item
              className='mb-0'
              name='username'
              rules={[
                { required: true, message: 'Veuillez entrer un identifiant' }
              ]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>

          <Col span={24} lg={12}>
            <label className='d-block mb-1'>Rôle <span className='text-danger'>*</span></label>
            <Form.Item
              className='mb-0'
              name='roles'
              rules={[{ required: true, message: 'Veuillez sélectionner un rôle' }]}
            >
              <Select size='large' loading={isLoading} options={roles?.map((role: any) => { return {value: role.id, label: role.NomDuRole} })} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <label className='d-block mb-1'>Email <span className='text-danger'>*</span></label>
            <Form.Item
              className='mb-0'
              name='email'
              rules={[
                { required: true, message: 'Veuillez entrer l\'adresse email' },
                { type: 'email', message: 'Adresse email invalide' }
              ]}
            >
              <Input type='email' size='large' />
            </Form.Item>
          </Col>

          <Col span={24}>
            <label className='d-block mb-1'>Mot de passe <span className='text-danger'>*</span></label>
            <Form.Item
              className='mb-0'
              name='password'
              rules={[
                { required: true, message: 'Veuillez entrer le mot de passe' },
                {
                  pattern:
                    /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
                  message:
                    'Le mot de passe doit contenir au moins 8 caractères, dont au moins une majuscule, un chiffre et un caractère spécial.'
                }
              ]}
            >
              <Input.Password size='large' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ModalUsers;
