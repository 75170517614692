import { Modal } from 'antd'
import EditForm from './EditForm'

function ModalEdit(props: any) {
  const { isModalEditOpen, handleModalClose, selectedUser, users, setUsers, disablePassword } =
    props

  return (
    <Modal
      centered
      open={isModalEditOpen}
      onCancel={handleModalClose}
      closable={false}
      footer={null}
    >
      {props.title && (
        <div className="mb-5 fw-bold fs-5 text-center">
          {props.title} {selectedUser.nom} {selectedUser.prenom}
        </div>
      )}

      <EditForm
        onSave={(user: any) => {
          handleModalClose()
          const filtredUsers = users?.filter(
            (u: any) => u.id !== selectedUser.id
          )
          filtredUsers.push(user)
          setUsers(filtredUsers)
        }}
        user={selectedUser}
        disablePassword={disablePassword}
        handleModalClose={handleModalClose}
      />
    </Modal>
  )
}

export default ModalEdit
