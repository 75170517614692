import { Outlet } from "react-router-dom";
import Header from "../components/header";
import { AuthStoreInstance } from "../helpers/Auth";

const Layout = () => (
  <>
    {AuthStoreInstance.isAuthenticated && <Header />}
    <Outlet />
  </>
);

export default Layout