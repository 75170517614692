import React from 'react'
import App from '@sidexia/visionformpreview'

interface Props {
  widgets: any[],
  setPreviewData: any,
  savePreview: any,
  previewData: any
}

const WidgetsViewLayout: React.FC<Props> = (props: any) => {
  const {widgets, setPreviewData, previewData} = props;

  return <App updateData={setPreviewData} initialElements={previewData} formData={widgets || []}/>
}

export default WidgetsViewLayout
