import { useState } from 'react'
import { useNavigate, useLoaderData } from 'react-router-dom'

import { Row, Col, Card, Select, Table, Space, Button, Switch, Progress } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import ApiRequest from '../../helpers/ApiRequest'
import { ENDPOINTS } from '../../constants/api'


const { Option } = Select
function Etats() {
  const navigate = useNavigate()
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true)
  const [savedData, setSavedData] = useState<any[]>([])
  const {etat, etats, widgets, id}: any = useLoaderData()

  const handleBack = () => {
    navigate(-1)
  }

  const handleSwitchChange = (inputId: string, checked: boolean) => {
    const updateEtats: any = []

    etats.forEach((e: any) => {
      if (e.input_id === inputId) e.is_checked = checked

      updateEtats.push(e)
    })

    setSavedData(updateEtats)
    setIsSaveDisabled(false)
  }

  const returnDataSave = (item: any) => {
    const currentitem = etats?.find((e: any) => {
      return e.input_id === item.id
    })
    return currentitem !== undefined
  }

  const returnDataSelect = (item: any) => {
    const currentitem = etats?.find((e: any) => {
      return e.input_id === item.id
    })
    return currentitem
  }

  const getSwitchElement = (item: any) => {
    const ischecked = returnDataSave(item)

    return (
      <Space size='middle' className='w-100 justify-content-end'>
        <Switch
          id={item.id}
          checked={ischecked}
          onChange={(checked) => handleSwitchChange(item.id, checked)}
        />
      </Space>
    )
  }

  const getSelectElement = (item: any) => {
    const isSelected = returnDataSelect(item)

    return (
      <Select
        className='w-100'
        size='large'
        defaultValue={isSelected?.selectedCalculation}
        onChange={(value) => handleCalculationChange(isSelected?.input_id, value)}
      >
        <Option value={null}>&nbsp;</Option>
        <Option value="+">Somme</Option>
        <Option value="/">Division</Option>
      </Select>
    )
  }

  const handleCalculationChange = (inputId: string, value: string) => {
    const updateEtats: any = []
    savedData.map((s: any) => {
      if (s.input_id === inputId) {
        s.selectedCalculation = value
      }
      updateEtats.push(s)
    })
    setSavedData(updateEtats)
    setIsSaveDisabled(false)
  }

  const handleSave = async () => {
    await ApiRequest.request({data: {
      selectedData: savedData as any,
    }, method: 'POST', endpoint: ENDPOINTS.saveEtat})
  }

  interface Etat {
    titre: string;
    cat: string;
    select: JSX.Element;
    switch: JSX.Element;
  }

  const dataTable = widgets.flatMap((widget: any) =>
    widget.settings.map((setting: any) => ({
      titre: setting.questions,
      cat: widget.nom,
      select: getSelectElement(widget),
      switch: getSwitchElement(widget)
    }))
  )

  const columns = [
    {
      title: 'Titre',
      dataIndex: 'titre',
      key: 'titre',
      className: 'fw-bold',
      sorter: (a: Etat, b: Etat) => a.titre.localeCompare(b.titre),
      showSorterTooltip: false
    },
    {
      title: 'Catégorie',
      dataIndex: 'cat',
      sorter: (a: Etat, b: Etat) => a.cat.localeCompare(b.cat),
      showSorterTooltip: false
    },
    {
      title: 'Type',
      dataIndex: 'select',
      width: 200,
      render: (select: any) => select
    },
    {
      title: '-',
      dataIndex: 'switch',
      width: 120,
      className: 'text-center',
      render: (check: any) => check
    }
  ]

  return (
    <main>
      <div className='container'>
        <Space className='w-100 justify-content-between'>
          <Button size='large' onClick={handleBack}>
            <FontAwesomeIcon icon={faChevronLeft} className='me-2' /> Retour
          </Button>
        </Space>


        <Card className="mt-5 text-center border-linear">
          <Row justify="center" align="middle">
            <Col lg={12}>
              <h1 className="my-0">{etat.nom}</h1>
              { etat.description && <div className='mt-5'>{etat.description}</div> }
            </Col>

            <Col lg={12}>
              <Space size="large">
                <div>
                  <Progress
                    type="dashboard"
                    size="small"
                    percent={100}
                    format={() => widgets.length}
                  />
                  <div className="fw-bold">Catégories</div>
                </div>
              </Space>
            </Col>
          </Row>
        </Card>

        <Space className='w-100 justify-content-end mt-10'>
          <Button size='large' disabled={isSaveDisabled} onClick={handleSave}>
            Enregistrer
          </Button>
        </Space>

        <Card className='mt-3 p-2'>
          <Table
            columns={columns}
            dataSource={dataTable}
            pagination={ dataTable.length > 15 ? { pageSize: 15 } : false }
          />
        </Card>
      </div>
    </main>
  )
}

export default Etats
