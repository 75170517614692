import { useState } from 'react'

import { Row, Col, Space, Modal, Button, Input, Checkbox, Badge, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import colorPalettes from './colorPalettes'


function ModalWidget(props: any) {
  const { TextArea } = Input

  const {
    isModalOpen,
    handleModalClose,
    handleValidClick,
  } = props

  const [name, setName] = useState(props.name || '')
  const [color, setColor] = useState(props.widget_color || '');
  const [isDefault, setIsDefault] = useState(props.isDefault || false)
  const [description, setDescription] = useState(props.description || '')
  const showDefault = window.localStorage.getItem('hasDefault') !== 'true' || isDefault

  const getColorPalettes = () => {
    return (
      <Space>
        { // todo - changer aussi dans widget
          colorPalettes.map(((colorData: any) => {
            return (
              <Tooltip key={colorData.name} arrow={false} overlayClassName='small' placement='bottom' title={colorData.name}>
                <Badge count={color === colorData.name ? <FontAwesomeIcon icon={faCircleCheck} className='text-default' /> : ''}>
                  <div style={{background: colorData.fill}} className={'ant-color-picker-trigger ' + colorData.class + (color === colorData.name ? ' selected' : '')} onClick={() => {setColor(colorData.name)}}></div>
                </Badge>
              </Tooltip>
            )
          }))
        }
      </Space>
    )
  }

  const modalFooter = () => {
    return (
      <Space>
        <Button danger type='dashed' size='large' onClick={handleModalClose}>Annuler</Button>

        <Button size='large' onClick={() => handleValidClick({ nom: name, description: description, default: isDefault, widget_color: color })} disabled={name.length < 1}>
          Valider
        </Button>
      </Space>
    )
  }

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleModalClose}
      closable={false}
      footer={modalFooter()}
      destroyOnClose
    >
      { props.title && <div className='mb-5 fw-bold fs-5 text-center'>{ props.title }</div> }

      <Row gutter={[16, 24]}>
        <Col span={24}>
          <label className='d-block mb-1'>Titre <span className='text-danger'>*</span></label>
          <Input
            id="name"
            type="nom"
            size='large'
            autoFocus
            value={name}
            required
            onChange={(e) => {setName(e.target.value)}}
          />
        </Col>
        <Col span={24}>
          <label className='d-block mb-1'>Description</label>
          <TextArea
            id="outlined-multiline-static"
            rows={4}
            autoFocus
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <label className='d-block mb-1'>Couleur de fond du widget</label>

          { getColorPalettes() }
        </Col>
        {/* todo // revoir cette partie du widget par défaut & changer checkbox par switch */}
        {
          showDefault &&
          (
            <Col span={24}>
              <div className='d-flex align-items-center justify-content-between p-3 bg-default-1 rounded-2'>
                Définir ce widget par défaut
                <Checkbox checked={isDefault} onChange={(e) => {setIsDefault(e.target.checked)}} />
              </div>
            </Col>
          )
        }
      </Row>


    </Modal>
  )
}

export default ModalWidget
